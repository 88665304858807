module.exports = [{
      plugin: require('/home/orhan/Desktop/careers-frontend/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"showCaptions":true},
    },{
      plugin: require('/home/orhan/Desktop/careers-frontend/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/orhan/Desktop/careers-frontend/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/orhan/Desktop/careers-frontend/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
